<template>
  <v-row>
    <v-col cols="12" sm="12">
      <v-row>
        <v-col cols="6" xs="6" md="6">
          <h1 class="primary--text">Clients</h1>
        </v-col>

        <v-col cols="6" xs="6" md="6">
          <v-text-field
            v-model="search"
            dense
            outlined
            :prepend-inner-icon="mdiMagnify"
            label="Search My History.."
            class="float-right"
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>
      
      <v-row class="mt-0">
        <v-col cols="12" xs="12" md="12">
          <v-card>
            <v-card-text class="px-0 pt-0">
              <v-skeleton-loader
                v-if="isLoading"
                type="table"
                class="mr-2 mt-6"
              >
              </v-skeleton-loader>
              <!-- table data here  -->
              <v-data-table
                v-else
                v-model="selected"
                :headers="headers"
                :items="allClients.items"
                :sort-by="['updated_at']"
                :sort-desc="[true]"
                :search="search"
                hide-default-footer
                disable-pagination
              >
                <template v-slot:[`item.photo`]="{ item }">
                  <v-avatar size="40px">
                    <v-img v-if="!imgError" :src="getAvatar(item.users)" @error="onImgError()"></v-img>
                    <v-img v-else :src="$tools.defaultImage()"></v-img>
                  </v-avatar>
                </template>

                <template v-slot:[`item.account_name`]="{ item }">
                  <span>{{item.account_name}}</span>
                </template>

                <template v-slot:[`item.client_name`]="{ item }">
                  <span>{{getTableItems(item.users, 'first_name')}} {{getTableItems(item.users, 'last_name')}}</span>
                </template>

                <template v-slot:[`item.email`]="{ item }">
                  <span>{{ getTableItems(item.users, 'email') }}</span>
                </template>

                <template v-slot:[`item.created_at`]="{ item }">
                  {{ $tools.dateTimeToStr(getTableItems(item.users, 'created_at'), "yyyy-mm-dd") }}
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-select
                    class="custom-width text-capitalize"
                    :items="(item.status=='created' ? [] : status_list)"
                    item-text="text"
                    item-value="value"
                    v-model="item.status"
                    :placeholder="item.status"
                    dense
                    outlined
                    v-on:change="changeEvent(item.users, $event)"
                  ></v-select>
                </template>
              </v-data-table>
            </v-card-text>

            <v-card-actions class="px-0">
              <div v-if="!allClients.total"></div>
              <v-row v-else>
                <v-col class="py-0" cols="12" md="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" offset-md="3">
                  <v-pagination
                    class="text-center"
                    v-model="page"
                    :length="Math.ceil(allClients.total / selectRow)"
                    @input="handlePageChange"
                  ></v-pagination>
                </v-col>
                <v-col cols="12" offset-sm="1" offset-md="1" xs="3" sm="2" md="2">
                  <v-select
                    v-model="selectRow"
                    :items="rows"
                    hide-details="auto"
                    class="px-3"
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-actions>

          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <DialogModal
      v-model="confirm_dialog"
      title="Confirm"
      :message="dialog_msg"
      @onCancel="cancelUpdate()"
      @onConfirmation="onUpdateConfirmed()"
    />

  </v-row>
</template>


<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { mdiMagnify } from '@mdi/js'

import {
  LOAD_ALL_CLIENTS, REFRESHTOKEN, UPDATE_CLIENTS
} from "@/store/_actiontypes";
import DialogModal from "@/components/common/DialogModal";


export default {
  components: {
    DialogModal
  },

  props: {
    status_list: {
      type: Array,
      default(){
        return [
          {
            text: "Active",
            value: "active",
          },
          {
            text: "Inactive",
            value: "inactive",
          }
        ];
      }
    },
    headers: {
      type: Array,
      default(){
        return [
          {
            text: "Photo",
            align: "start",
            sortable: true,
            value: "photo",
          },
          {
            text: "Account Name",
            value: "account_name",
            sortable: true,
            align: "start",
          },
          {
            text: "Client Name",
            value: "client_name",
            sortable: true,
            align: "start",
          },
          {
            text: "Email",
            value: "email",
            sortable: true,
            align: "start",
          },
          {
            text: "Created",
            align: "start",
            sortable: true,
            value: "created_at",
          },
          {
            text: "Action",
            align: "start",
            sortable: true,
            value: "status",
          },
        ]
      }
    }
  },

  watch: {
    selectRow(){
      this.page = 1;
      this.getAllClients();
    }
  },

  computed: {
    ...mapState({
      allClients: (state) => state.clients.all,
      user: (state) => state.account.user,
    }),
    ...mapGetters("loader", ["loading"]),
  },

  mounted(){
    this.getAllClients();
  },

  data(){
    return {
      mdiMagnify,
      memberData: {},
      confirm_dialog: false,
      change_request_id: '',
      change_event_val: '',
      dialog_msg: '',
      isLoading: false,
      detailsDialog: false,
      imgError: false,
      search: '',
      page: 1,
      pageCount: 0,
      selectRow: 10,
      dates: [],
      selected: [],
      rows: [5, 10, 20, 50, 100],
    };
  },

  methods: {
    ...mapActions("clients", [LOAD_ALL_CLIENTS, UPDATE_CLIENTS]),
    ...mapActions("account", [REFRESHTOKEN]),

    getAllClients(){
      this.isLoading = true;
      if (this.$tools.timeToNextToken() < 300){
        this.REFRESHTOKEN({
          refresh_token: this.user.refresh_token,
        }).then((response) => {
          this.getAllClientsFunction();
        }, (error) => error
        );
      } else {
        this.getAllClientsFunction();
      }
    },
    getAllClientsFunction(){
      this.LOAD_ALL_CLIENTS({
        start_date: "",
        end_date: "",
        job_status: "",
        per_page: this.selectRow,
        page: this.page,
      }).then(
        (response) => {
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
    },
    cancelUpdate(){
      this.confirm_dialog = false;
      this.getAllClients();
    },
    changeEvent(item, event){
      let dialog_msg = `Are you sure to update status for ${item[0].email}?`;
      this.dialog_msg = dialog_msg;
      this.confirm_dialog = true;
      this.change_request_id = item[0].account_id;
      this.change_event_val = event;
    },
    onUpdateConfirmed(){
      this.confirm_dialog = false;
      this.isLoading = true;

      this.UPDATE_CLIENTS({
        account_id: this.change_request_id,
        status: this.change_event_val
      }).then((res) => {
        this.isLoading = false;
        this.getAllClients();
      }).catch((err) => {
        this.isLoading = false;
      });
    },
    handlePageChange(value){
      this.page = value
      this.getAllClients();
    },
    getTableItems(data, key){
      if(data.hasOwnProperty(0)){
        return data[0][key]
      }
    },
    getAvatar(data){
      let photo = this.getTableItems(data, 'photo');
      return photo ? this.$tools.getEnv("VUE_APP_FILE_SERVER")+photo : "";
    },
		onImgError(){
			this.imgError = true;
		},
  },
};
</script>

<style>
.custom-width{
  width: 115px;
  margin-bottom: -26px !important;
}
</style>

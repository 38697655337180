var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6","xs":"6","md":"6"}},[_c('h1',{staticClass:"primary--text"},[_vm._v("Clients")])]),_c('v-col',{attrs:{"cols":"6","xs":"6","md":"6"}},[_c('v-text-field',{staticClass:"float-right",attrs:{"dense":"","outlined":"","prepend-inner-icon":_vm.mdiMagnify,"label":"Search My History..","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","xs":"12","md":"12"}},[_c('v-card',[_c('v-card-text',{staticClass:"px-0 pt-0"},[(_vm.isLoading)?_c('v-skeleton-loader',{staticClass:"mr-2 mt-6",attrs:{"type":"table"}}):_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.allClients.items,"sort-by":['updated_at'],"sort-desc":[true],"search":_vm.search,"hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.photo",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"40px"}},[(!_vm.imgError)?_c('v-img',{attrs:{"src":_vm.getAvatar(item.users)},on:{"error":function($event){return _vm.onImgError()}}}):_c('v-img',{attrs:{"src":_vm.$tools.defaultImage()}})],1)]}},{key:"item.account_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.account_name))])]}},{key:"item.client_name",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTableItems(item.users, 'first_name'))+" "+_vm._s(_vm.getTableItems(item.users, 'last_name')))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getTableItems(item.users, 'email')))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$tools.dateTimeToStr(_vm.getTableItems(item.users, 'created_at'), "yyyy-mm-dd"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-select',{staticClass:"custom-width text-capitalize",attrs:{"items":(item.status=='created' ? [] : _vm.status_list),"item-text":"text","item-value":"value","placeholder":item.status,"dense":"","outlined":""},on:{"change":function($event){return _vm.changeEvent(item.users, $event)}},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-card-actions',{staticClass:"px-0"},[(!_vm.allClients.total)?_c('div'):_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","md":"12"}},[_c('v-divider')],1),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","offset-md":"3"}},[_c('v-pagination',{staticClass:"text-center",attrs:{"length":Math.ceil(_vm.allClients.total / _vm.selectRow)},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"12","offset-sm":"1","offset-md":"1","xs":"3","sm":"2","md":"2"}},[_c('v-select',{staticClass:"px-3",attrs:{"items":_vm.rows,"hide-details":"auto","outlined":"","dense":""},model:{value:(_vm.selectRow),callback:function ($$v) {_vm.selectRow=$$v},expression:"selectRow"}})],1)],1)],1)],1)],1)],1)],1),_c('DialogModal',{attrs:{"title":"Confirm","message":_vm.dialog_msg},on:{"onCancel":function($event){return _vm.cancelUpdate()},"onConfirmation":function($event){return _vm.onUpdateConfirmed()}},model:{value:(_vm.confirm_dialog),callback:function ($$v) {_vm.confirm_dialog=$$v},expression:"confirm_dialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }